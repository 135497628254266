<template>
  <div class="yx-wid footer">
    <div class="yx-wid-12">
      <!-- <ul class="link-list">
        <li v-for="(item, index) in links" :key="index">
          <img :src="item.img" class="mr" />
          <img :src="item.imgHover" class="move" />
        </li>
      </ul>
      <div class="copy" v-html="$t('footer.smcopy')"></div>
      <ul class="rz-list">
        <li>
          <span></span>
          <p>{{ $t("footer.pttext") }}</p>
        </li>

        <li>
          <span></span>
          <p>{{ $t("footer.pttext1") }}</p>
        </li>

        <li>
          <span></span>
          <p>{{ $t("footer.pttext2") }}</p>
        </li>
      </ul> -->
      <div class="th-footer">
        <ul>
          <li>
            <h3>合作合伙</h3>
            <img src="@/assets/pic/footer-corp.png" class="img" />
          </li>
          <li>
            <h3>支付方式</h3>
            <img src="@/assets/pic/footer-pago.png" class="img" />
          </li>
          <li>
            <h3>监管机构</h3>
            <img src="@/assets/pic/footer-payment.png" class="img" />
          </li>
          <li>
            <h3>经营牌照</h3>
            <img src="@/assets/pic/download-license.png" class="img1" />
            <p>平台安全运营<span>3526天</span> 不忘初心砥砺前行</p>
          </li>
        </ul>
      </div>
      <div class="guide-list">
        <span
          @click="jumpGO(item)"
          v-for="(item, index) in guides"
          :key="index"
          >{{ item.name }}</span
        >
      </div>
      <div class="copy-text">
        头号玩家持菲律宾 PAGCOR合法牌照 2014-2023 版权所有©头号玩家 侵权必究
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "",
          img: require("@/assets/images/logo/logo01-1.png"),
          imgHover: require("@/assets/images/logo/logo01.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo02-1.png"),
          imgHover: require("@/assets/images/logo/logo02.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo03-1.png"),
          imgHover: require("@/assets/images/logo/logo03.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo04-1.png"),
          imgHover: require("@/assets/images/logo/logo04.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo05-1.png"),
          imgHover: require("@/assets/images/logo/logo05.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo06-1.png"),
          imgHover: require("@/assets/images/logo/logo06.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo07-1.png"),
          imgHover: require("@/assets/images/logo/logo07.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo08-1.png"),
          imgHover: require("@/assets/images/logo/logo08.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo09-1.png"),
          imgHover: require("@/assets/images/logo/logo09.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo10-1.png"),
          imgHover: require("@/assets/images/logo/logo10.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo11-1.png"),
          imgHover: require("@/assets/images/logo/logo11.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo12-1.png"),
          imgHover: require("@/assets/images/logo/logo12.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo13-1.png"),
          imgHover: require("@/assets/images/logo/logo13.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo14-1.png"),
          imgHover: require("@/assets/images/logo/logo14.png"),
        },
        {
          name: "",
          img: require("@/assets/images/logo/logo15.png"),
          imgHover: require("@/assets/images/logo/logo15.png"),
        },
      ],
      guides: [
        { name: "关于头号玩家", link: "/help/index/gywm/ppjs" },
        { name: "牌照展示", link: "/help/index/gywm/jgwm" },
        { name: "隐私与条款", link: "/help/index/gywm/gzytk" },
        { name: "合作加盟", link: "/agen/index" },
        { name: "意见建议", link: "/feedback" },
        { name: "体育规则", link: "/sports/index/jctzgz/jctzgz" },
      ],
    };
  },
  methods: {
    jumpGO(val) {
      console.log(val.link);
      this.$router.push(val.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #333;
  width: 100%;
  text-align: center;
  position: relative;
  line-height: 1;
}

.link-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: rgba(113, 128, 153, 0.2);
  }

  li {
    &:hover {
      .mr {
        display: none;
      }

      .move {
        display: block;
      }
    }
  }

  img {
    height: 50px;
    filter: grayscale(100%);

    &.move {
      display: none;
      filter: grayscale(0%);
    }
  }
}

.copy {
  display: flex;
  justify-content: center;
  line-height: 1.4;
  text-align: center;
  padding: 0 130px;
  margin-top: 18px;
  font-size: 14px;
  color: #888888;
  flex-direction: column;
}

.rz-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  li {
    margin: 0 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &:hover {
      color: white;

      p {
        color: white;
      }

      span {
        background-position: -167px 0;
        filter: grayscale(0);
      }
    }

    span {
      width: 165px;
      height: 34px;
      display: block;
      background: url("@/assets/images/logo15.png") no-repeat;
      background-size: 958px 41px;
      background-position: 0 0;
      filter: grayscale(100%);
    }

    p {
      font-size: 14px;
      color: #888888;
      margin-top: 8px;
    }

    &:nth-child(2) {
      &:hover {
        span {
          background-position: -507px 0;
        }
      }

      span {
        width: 171px;
        height: 41px;
        background-position: -334px 0;
      }
    }

    &:nth-child(3) {
      &:hover {
        span {
          background-position: -820px 0;
        }
      }

      span {
        width: 138px;
        height: 41px;
        background-position: -680px 0;
      }
    }
  }
}

.guide-list {
  display: flex;
  justify-content: center;
  color: #888888;
  font-size: 14px;
  padding: 30px 0 0;

  span {
    position: relative;
    padding: 0 30px;
    color: #888888;
    border-right: 1px solid #888888;
    cursor: pointer;

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: var(--theme);
    }
  }
}

.copy-text {
  text-align: center;
  margin-top: 8px;
  line-height: 32px;
  font-size: 12px;
  color: #888;
  padding-bottom: 20px;
}
.th-footer {
  ul {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 30px 0;
    border-bottom: 1px solid #444;
    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
    }
    .img {
      height: 65px;
    }
    .img1 {
      height: 38px;
    }
    h3 {
      font-size: 16px;
      font-weight: normal;
      color: #cccccc;
    }
    p {
      color: #888;
      font-size: 12px;
      span {
        color: var(--theme);
      }
    }
  }
}
</style>
