<template>
  <div class="yx-wid-12 vipCenter">
    <div class="user-left" v-if="userInfo">
      <!-- 头像部分 -->
      <div class="userInfo">
        <div class="photo" @click="setPhoto">
          <img :src="userInfo.photoUrl" />
          <em>编辑头像</em>
        </div>
        <div class="name">
          <span class="text">{{ userInfo.userName }}</span>
          <span class="level">
            <img
              v-if="userInfo && userInfo.levelId >= 0"
              :src="
                require('@/assets/pic/index-level-' + userInfo.levelId + '.png')
              "
            />
          </span>
        </div>
        <p class="time" v-if="userInfo && baseInfo && baseInfo[2]">
          {{ $t("mine.index.252217-0") }}{{ baseInfo[2].configValue
          }}{{ userInfo.joinDays }}{{ $t("mine.index.252217-1") }}
        </p>
        <ul>
          <li
            :class="{ hover: userInfo.mobile }"
            @click="$router.push('/mine/usersecure')"
          >
            <p>{{ userInfo.mobile ? "手机号码已绑定" : "手机号未绑定" }}</p>
            <span class="icon icon-mobile"> </span>
          </li>
          <li
            :class="{ hover: isBankList }"
            @click="$router.push('/mine/usersecure')"
          >
            <p>{{ isBankList ? "银行卡已绑定" : "银行卡未绑定" }}</p>
            <span class="icon icon-bank"> </span>
          </li>
          <li
            :class="{ hover: userInfo.email }"
            @click="$router.push('/mine/usersecure')"
          >
            <p>{{ userInfo.email ? "邮箱已绑定" : "邮箱未绑定" }}</p>
            <span class="icon icon-email"> </span>
          </li>
          <li
            :class="{
              hover:
                userInfo.realName &&
                userInfo.birthDate &&
                [0, 1].includes(userInfo.sex),
            }"
            @click="$router.push('/mine/usersecure')"
          >
            <p>
              {{
                userInfo.realName &&
                userInfo.birthDate &&
                [0, 1].includes(userInfo.sex)
                  ? "个人信息已设置"
                  : "个人信息未设置"
              }}
            </p>
            <span class="icon icon-user"> </span>
          </li>
        </ul>
      </div>
      <!-- menus -->
      <div class="user-nav">
        <ul>
          <li
            v-for="(item, index) in menus"
            :key="index"
            :class="{ hover: item.aid === menuInd }"
            @click="tabMenu(item)"
          >
            <span class="icon" :class="item.icon"></span>
            <span class="text">{{ item.name }}</span>
            <span class="message" v-if="item.aid === 6 && message > 0">{{
              message
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <router-view class="th-user-right"></router-view>
    <SetPhoto
      v-if="isExitPhoto && userInfo && userInfo.photoUrl"
      :visible.sync="isExitPhoto"
      :title="$t('mine.index.252217-39')"
    />
    <helpCenter v-if="isHelp" :visible.sync="isHelp" :scrollTop="helpTop" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SetPhoto from "@/components/SetPhoto.vue";
import helpCenter from "@/components/TransferHelp.vue";
export default {
  components: { SetPhoto, helpCenter },
  data() {
    return {
      isHelp: false,
      helpTop: 0,
      menuInd: undefined,
      menus: [
        {
          name: "账户总览",
          link: "/mine/accountInfo",
          aid: "accountInfo",
          icon: "icon-totalInfo",
        },
        {
          name: "账户清单",
          link: "/record/transRecord",
          aid: "transRecord",
          icon: "icon-order",
        },
        {
          name: "投注记录",
          link: "/record/betRecord",
          aid: "betRecord",
          icon: "icon-records",
        },
        {
          name: "福利中心",
          link: "/record/welfare",
          aid: "welfare",
          icon: "icon-discount",
        },
        {
          name: "账户安全",
          link: "/mine/usersecure",
          aid: "usersecure",
          icon: "icon-secure",
        },
        {
          name: "个人资料",
          link: "/mine/userInfo",
          aid: "userInfo",
          icon: "icon-card",
        },
        {
          name: "消息中心",
          link: "/mail",
          aid: "mail",
          icon: "icon-message1",
        },
        // {
        //   name: "专属客服",
        //   link: "/record/giftRecord",
        //   aid: 7,
        //   icon: "icon-contact",
        // },
        // {
        //   name: this.$t("mine.index.252217-47"),
        //   link: "/feedback",
        //   aid: "feedback",
        //   icon: "icon-contact",
        // },
      ],
      isExitPhoto: false,
      carkInd: 0,
      isShowRight: false,
      isHover: "",
      recordList: null, //投注列表
      // 卡片参数
      bankListMenu: [
        {
          name: this.$t("mine.index.252217-8"),
          type: 1,
          moreLink: "/finance/wallet",
          addLink: "/addBankCard",
        },
        {
          name: this.$t("mine.index.252217-9"),
          type: 2,
          moreLink: "/virtualCoinCard",
          addLink: "/virtualCoinCard",
        },
      ],
      bankList: null,
      // 存款参数
      ckList: null,
      // 反馈列表
      facebookList: null,
      // 活动列表
      activityList: null,
    };
  },
  watch: {
    $route(val) {
      if (val.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }

      this.infoLeftVal();
    },
    isLogin(val) {
      if (val) {
        this.getUserInfo();
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "message", "isBankList"]),
  },
  mounted() {
    this.infoRoute();
    this.infoLeftVal();
    this.getUnReadMessageCount();
    this.getBankPost();
    console.log(this.$route);
  },
  methods: {
    getBankPost() {
      this.$Api.queryUserBankCardList().then((res) => {
        if (res) {
          this.$store.dispatch("setBankListAc", !!res.userBankCardList.length);
        }
      });
    },
    openHelp(val) {
      this.isHelp = true;
      document.body.style = "overflow:hidden";
      this.helpTop = val;
    },
    // 个人资料
    getUserInfo() {
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
        }
      });
    },
    // 设置头像
    setPhoto() {
      this.isExitPhoto = true;
    },
    // message
    getUnReadMessageCount() {
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageAc", message);
        }
      });
    },
    infoLeftVal() {
      if (this.$route.name) {
        this.menuInd = this.$route.name;
      }
      console.log(this.menuInd);
    },
    infoRoute() {
      if (this.$route.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }
    },
    tabMenu(item) {
      this.menuInd = item.aid;
      this.$router.push(item.link);
    },
    convertType(val) {
      let textVal = "";
      switch (val) {
        case 1:
          textVal = this.$t("mine.index.252217-53");
          break;
        case 2:
          textVal = this.$t("mine.index.252217-54");
          break;
        case 3:
          textVal = this.$t("mine.index.252217-55");
          break;
        case 4:
          textVal = this.$t("mine.index.252217-56");
          break;
        case 5:
          textVal = this.$t("mine.index.252217-57");
          break;
        case 6:
          textVal = this.$t("mine.index.252217-58");
          break;
      }
      return textVal;
    },
    typeName(val) {
      const options = [
        {
          value: 1,
          label: this.$t("mine.index.252217-59"),
        },
        {
          value: 2,
          label: this.$t("mine.index.252217-60"),
        },
        {
          value: 3,
          label: this.$t("mine.index.252217-61"),
        },
        {
          value: 4,
          label: this.$t("mine.index.252217-62"),
        },
        {
          value: 5,
          label: this.$t("mine.index.252217-63"),
        },
        {
          value: 6,
          label: this.$t("mine.index.252217-64"),
        },
        {
          value: 7,
          label: this.$t("mine.index.252217-65"),
        },
        {
          value: 8,
          label: this.$t("mine.index.252217-66"),
        },
        {
          value: 9,
          label: this.$t("mine.index.252217-67"),
        },
      ];
      return options.find((item) => item.value === val).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.vipCenter {
  display: flex;
  padding: 30px 0 60px;
  gap: 20px;
}

.user-left {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 226px;
  height: auto;
  flex: none;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("@/assets/pic/user-bg.png") no-repeat;
  background-size: cover;
  height: 318px;
  padding-top: 50px;

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    em {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 12px;
      color: white;
      background: var(--theme);
      z-index: 2;
      text-align: center;
      font-style: normal;
    }

    &:hover {
      em {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 6px;

    .text {
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      color: #edc79a;
      font-weight: 400;
      font-family: DIN, PingFangSC-Regular, sans-serif !important;
    }

    .level {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 14px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .time {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    margin-top: 4px;
    color: rgba(255, 255, 255, 0.8);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    gap: 10px;
    margin-top: 34px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      &.hover {
        .icon {
          color: var(--theme);
        }
      }
      &:hover {
        p {
          display: block;
        }
      }
      &:last-child {
        p {
          left: auto;
          right: 0;
          &::before {
            left: auto;
            right: 15px;
          }
        }
      }
      p {
        position: absolute;
        z-index: 8;
        left: 0px;
        top: -40px;
        background: #f6ead9;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        font-size: 12px;
        color: #111111;
        width: max-content;
        display: none;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 0;
          height: 0;
          top: 27px;
          left: 15px;
          border: 5px solid transparent;
          border-top: 5px solid #f6ead9;
        }
      }

      .icon {
        position: relative;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.08);
        font-size: 18px;
        color: #999;
      }
    }
  }
}

.user-nav {
  ul {
    padding: 16px 0;
    border-bottom: 1px solid #e4e8eb;

    &:last-child {
      border-bottom: none;
    }

    li {
      position: relative;
      background: transparent;
      border-left: 4px solid transparent;
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      height: 68px;
      font-size: 16px;
      padding: 0 20px 0 30px;

      &.hover,
      &:hover {
        background: #fffaef;

        .icon {
        }

        .text {
          color: var(--theme);
        }
      }
      &.hover {
        border-left-color: var(--theme);
      }
      .text {
        flex: 1;
        overflow: hidden;
      }

      .icon {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--theme);
        background: linear-gradient(180deg, #fff, #ddd);
        border: 1px solid white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2),
          0 3px 5px inset rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.user-jv {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  background-color: #fff;
  width: 200px;
  height: auto;
  min-height: 360px;
  padding: 16px 14px;

  &:last-child {
    flex: 1 1;
  }

  .title {
    display: flex;
    justify-content: space-between;

    .text {
      color: #30383f;
      text-align: justify;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .more {
      color: #2599f8;
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-top: 1px solid #2599f8;
        border-right: 1px solid #2599f8;
      }
    }
  }

  &-node {
    font-size: 12px;
    line-height: 17px;
    margin-top: 150px;
    text-align: center;
    color: #788b9d;

    span {
      color: #2599f8;
      cursor: pointer;
    }
  }
}

.cark-title {
  ul {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      border-bottom: 1px solid #f0f0f0;
    }

    li {
      color: #48535e;
      text-align: center;
      height: 30px;
      padding: 2px 0 12px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.hover {
        color: #2599f8;
        border-bottom: 2px solid #2599f8;
      }
    }
  }
}

.user-cark {
  margin-top: 16px;
  min-height: 460px;
  flex: 1 1;
}

.user-right {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  flex: none;
  z-index: 1;
}

.discount-list {
  margin-top: 12px;

  li {
    width: 100%;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.pay-jl-list {
  li {
    margin-top: 8px;
    padding: 12px 8px;
    height: 64px;
    border-bottom: none;

    .name {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      height: 17px;
      color: #788b9d;
      margin-bottom: 4px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
      }
    }

    .row {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      height: 19px;
      color: #30383f;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-family: Akrobat;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        height: 19px;
        color: #30383f;
      }

      .right {
        background: rgba(175, 179, 200, 0.2);
        border-radius: 2px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #aeb9c4;
        padding: 4px;

        &.success {
          color: #4ab06a;
          background: rgba(74, 176, 106, 0.2);
        }
      }
    }
  }
}

.help-list {
  margin-top: 20px;

  li {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #48535e;
  }
}

.user-jv-list {
  ul {
    li {
      border-bottom: 1px solid #f2f2f6;
      padding: 12px 0 8px;
      cursor: pointer;

      span {
        color: #a5a9b3;
        font-size: 12px;
        line-height: 12px;
      }

      p {
        line-height: 20px;
        margin-top: 2px;
        color: #414655;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.message {
  min-width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  padding: 0 5px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  background: rgb(234, 36, 36);
}

.bankList-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 0;

  span {
    cursor: pointer;
  }
}

.card-list {
  li {
    width: 172px;
    height: 96px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px 0 rgba(240, 176, 176, 0.4);
    background-image: linear-gradient(298deg, #d57074, #f7acaf);
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 16px;

    &.usdt {
      background-image: linear-gradient(118deg, #4de2b8, #45ba99 99%);
      box-shadow: 0 4px 10px 0 rgba(72, 200, 164, 0.4);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .sec-img {
      width: 28px;
      height: 28px;
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      vertical-align: top;
      flex: none;
      overflow: hidden;
      padding: 6px;
    }

    .sec {
      color: white;
      font-size: 12px;
      margin-left: 8px;

      .sec-text {
        opacity: 0.5;
      }
    }

    .sec-bankNo {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 16px;
      text-align: center;
      color: white;
      font-size: 12px;
    }
  }
}
.th-user-right {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  flex: 1;
}
</style>
