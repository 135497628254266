<template>
  <div class="yx-wid th-header">
    <div class="yx-wid-12 th-header-wid">
      <div class="th-agen-left">
        <img
          v-if="baseInfo"
          :src="baseInfo[16].configValue"
          class="logo"
          @click="$router.push('/')"
        />

        <img src="@/assets/pic/logo.gif" class="agen" />
      </div>
      <div class="th-agen-menus">
        <ul>
          <li
            v-for="(item, index) in agens"
            :key="index"
            :class="{ hover: item.type === hoverAgen }"
            @click="jumpLink(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverAgen: "",
      agens: [
        {
          name: "首页",
          link: "/agen/index",
          type: "agenHome",
        },
        {
          name: "关于我们",
          link: "/agen/about",
          type: "agenAbout",
        },
        {
          name: "游戏产品",
          link: "/agen/produce",
          type: "agenProduce",
        },
        {
          name: "代理福利",
          link: "/agen/welfare",
          type: "agenWelfare",
        },
        {
          name: "佣金方案",
          link: "/agen/case",
          type: "agenCase",
        },
        {
          name: "常见问题",
          link: "/agen/qa",
          type: "agenQa",
        },
      ],
    };
  },
  watch: {
    $route(val) {
      if (val) {
        this.initInfo();
      }
    },
  },
  mounted() {
    this.initInfo();
  },
  methods: {
    jumpLink(val) {
      this.$router.push(val.link);
    },
    initInfo() {
      this.hoverAgen = this.$route.meta.mapState;
    },
  },
};
</script>

<style scoped lang="scss">
.th-header {
  background: rgba(34, 34, 34, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  &-wid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0;
    .logo {
      height: 58px;
    }
    .agen {
      height: 60px;
    }
  }
}
.th-agen-menus {
  ul {
    display: flex;
    align-items: center;
    gap: 60px;
  }
  li {
    color: white;
    font-size: 16px;
    cursor: pointer;
    &.hover {
      color: var(--theme);
    }
  }
}
</style>
