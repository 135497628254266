<template>
  <div class="pop-check" ref="popDom">
    <div class="main">
      <div class="check-title">
        <strong>{{ title }}</strong>
        <span class="close icon-close" @click="closePop"></span>
      </div>
      <div class="check-main">
        <div class="target-photo" v-if="photoList">
          <img :src="defaultImg" />
        </div>
        <ul class="photo-list" v-if="photoList">
          <li
            v-for="(item, index) in photoList"
            :key="index"
            class="photo-list-li"
            :class="{ hover: index === photoIndex }"
            @click="selectImg(item, index)"
          >
            <img :src="item" />
          </li>
          <li class="photo-list-li icon-camera">
            <el-upload
              action=""
              list-type="picture-card"
              accept=".jpg,.gif,.png,.jpeg"
              :file-list="fileList"
              :limit="1"
              :http-request="upFileList"
              :on-remove="handleRemove"
              :on-change="handFileList"
              :auto-upload="false"
              :multiple="true"
              ref="upload"
            >
              <!-- <i class="el-icon-plus"></i> -->
            </el-upload>
          </li>
        </ul>
      </div>
      <div class="photo-link">
        <el-button type="primary" class="link" @click="submitPhoto">{{
          $t("components.SetPhoto.207060-0")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      photoIndex: 0,
      defaultImg: "",
      photoList: null,
      fileList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.initFrom();
      }
    },
  },
  mounted() {
    this.inittest();
  },
  methods: {
    // 上传图片
    upFileList() {
      this.loading = true;
      const params = {
        files: this.fileList[0].raw,
      };
      this.$Api.upLoadImgPost(params).then((res) => {
        if (res) {
          this.loading = false;
          this.$Api.modifyUserInfo({ photoUrl: res[0] }).then((res) => {
            if (res) {
              this.closePop();
              this.$Api.getUserInfo().then((res) => {
                if (res) {
                  this.$store.dispatch("setUserInfoAc", res.userInfo);
                }
              });
            }
          });
        }
      });
    },

    handFileList(file, fileList) {
      fileList.forEach((item, index) => {
        item.typeId = index;
      });
      this.fileList = fileList;
      this.defaultImg = fileList[0].url;
    },
    inittest() {
      const dom = this.$refs.popDom;
      document.body.appendChild(dom);
      const imgs = [];
      for (let i = 1; i < 9; i++) {
        const img = require("@/assets/images/photo/photo" + i + ".png");
        imgs.push(img);
      }
      this.photoList = imgs;
      this.defaultImg = this.userInfo.photoUrl;
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((item) => {
        if (file !== item) {
          return item;
        }
      });
    },
    selectImg(val, index) {
      this.photoIndex = index;
      this.defaultImg = val;
    },
    submitPhoto() {
      if (this.fileList && this.fileList.length) {
        this.upFileList();
        return;
      }
      const imgPhoto = location.origin + this.defaultImg;
      this.$Api.modifyUserInfo({ photoUrl: imgPhoto }).then((res) => {
        if (res) {
          this.closePop();
          this.$Api.getUserInfo().then((res) => {
            if (res) {
              this.$store.dispatch("setUserInfoAc", res.userInfo);
            }
          });
        }
      });
    },

    closePop() {
      this.$emit("update:visible");
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-check {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .main {
    background-color: #fff;
    width: 480px;
    border-radius: 10px;
    overflow: hidden;
  }
  .check-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 48px;
    padding: 0 32px;
    background: #111;
    position: relative;
    strong {
      font-size: 18px;
      font-weight: normal;
    }
    .close {
      position: absolute;
      top: 15%;
      right: 10px;
      width: 34px;
      height: 34px;
      // transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.4s;
      opacity: 0.5;
      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
}
.check-main {
  padding: 30px;
}
.target-photo {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: 20px auto 30px;
  img {
    width: 100%;
    height: 100%;
  }
}
.photo-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
  .photo-list-li {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 2px solid transparent;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    &.hover,
    &:hover {
      border-color: var(--theme);
      z-index: 2;
      &::before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border: 5px solid transparent;
        border-bottom-color: var(--theme);
      }
    }
    &:last-child {
      background: #eee;
      &::after {
        content: "\e936";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        color: var(--theme);
      }
      &::before {
        display: none;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.photo-link {
  width: 70%;
  margin: 0 auto 30px;
  .link {
    width: 100%;
  }
}
/deep/.el-upload--picture-card {
  background: none;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  border-radius: 100%;
}
</style>
