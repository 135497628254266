<template>
  <div class="header">
    <div class="header-wid">
      <div class="logo" @click="$router.push('/')">
        <img v-if="baseInfo && baseInfo.length" :src="baseText('logo')" />
      </div>
      <div class="nav" v-if="menus">
        <ul>
          <li
            v-for="(item, index) in menus"
            :key="index"
            @mousemove="tabSelect(item, index)"
            :class="{ hover: $route.meta.data === item.layout }"
            @click="jumpWeb(item)"
          >
            {{ item.gameTypeName }}
            <span
              class="arrow icon-arrow-d"
              v-if="item.list && item.list.length"
            ></span>
          </li>
        </ul>
      </div>
      <div class="help">
        <ul>
          <li
            v-for="(item, index) in helps"
            :key="index"
            @click="jumpHelp(item)"
            @mouseenter="tabSelectHelp(item, index)"
          >
            <span class="img" :class="item.icon"></span>
            <p class="text">{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <div class="login" v-if="!isLogin">
        <div class="btn-log" @click="openLoginPop">{{ $t("logins.dl") }}</div>
        <div class="btn-reg" @click="openRegisterPop">
          {{ $t("logins.zc") }}
        </div>
      </div>
      <div class="login-nav" v-if="isLogin">
        <div class="login-info">
          <img v-if="userInfo" :src="userInfo.photoUrl" class="photo" />
          <div class="info" v-if="userInfo">
            <span class="name"
              >{{ userInfo.userName
              }}<span
                class="level-img"
                v-if="userInfo.levelId"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/pic/index-level-' +
                      userInfo.levelId +
                      '.png') +
                    ')',
                }"
              ></span
            ></span>
            <p class="text-ak" v-if="accountMoney">
              ¥{{
                accountMoney.userBalance
                  ? accountMoney.userBalance.toFixed(2)
                  : "0.00"
              }}
              <em class="icon-arrow-d"></em>
            </p>
          </div>
          <div class="login-menus">
            <dl>
              <dd @click="checkAuthUrl('/finance/deposit')">
                <span class="icon icon-purse"></span>
                <p class="text">充值</p>
              </dd>
              <dd @click="checkAuthUrl('/finance/withdrawal')">
                <span class="icon icon-withdraw"></span>
                <p class="text">提现</p>
              </dd>
              <dd @click="$router.push('/finance/transfer')">
                <span class="icon icon-transfer"></span>
                <p class="text">转账</p>
              </dd>
            </dl>
            <ul>
              <li
                v-for="(item, index) in loginMenu"
                @click="$router.push(item.link)"
                :key="index"
              >
                <span class="icon" :class="item.icon"></span>
                <div class="flex">
                  <span class="text">{{ item.name }}</span>
                  <span class="number"></span>
                </div>
                <span class="icon-arrow-r"></span>
              </li>
            </ul>
            <div class="login-out" @click="loginOut">
              {{ $t("components.Header.877183-3") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 下拉导航 -->
    <div class="nav-bg fadeIn" v-if="isShowSelect" @mouseleave="moveOut">
      <!--  -->
      <div class="yx-wid-12">
        <!-- 样式1 -->
        <div
          class="nav-list"
          v-if="
            [
              'sport',
              'realbet',
              'chess',
              'gaming',
              'lottery',
              'egame',
              'fishing',
            ].includes(selectType)
          "
        >
          <div
            class="arrow prev"
            @click="arrowNavTab('prev')"
            v-if="navIndex > 0"
          ></div>
          <div
            class="arrow next"
            @click="arrowNavTab('next')"
            v-if="navIndex !== moveNavWid && isNavShow"
          ></div>
          <div class="yx-wid-12 nav-list-box" ref="targetTotal">
            <div
              class="nav-list-scroll"
              :style="{ transform: 'translateX(' + menuLeftVal + 'px)' }"
            >
              <div
                class="nav-row"
                v-for="(item, index) in menus[menuInd].list"
                :key="index"
                ref="targetGame"
              >
                <div class="left" @click="openGame(item)">
                  <img
                    v-if="item.pcNavbarImgUrl"
                    v-lazy="item.pcNavbarImgUrl"
                    class="img"
                  />
                  <img v-else src="@/assets/images/menus/01.png" class="img" />
                  <p>{{ item.apiTitle }}</p>
                </div>
              </div>
              <div class="nav-ty-img" v-if="menuInd === 1">
                <img src="@/assets/images/menus/04.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客服弹窗 -->
    <div class="contact-box" v-if="jumpContact">
      <div class="contact-main">
        <img src="@/assets/images/contact01.png" />
        <div class="main">
          <h2>{{ $t("components.Header.877183-12") }}</h2>
          <p class="text-red">
            {{ $t("components.Header.877183-13") }}
          </p>
          <p>
            {{ $t("components.Header.877183-14")
            }}<span class="text-red">{{
              $t("components.Header.877183-15")
            }}</span
            >{{ $t("components.Header.877183-16") }}
          </p>
          <el-button class="link" type="primary" @click="jumpContactWeb">{{
            $t("components.Header.877183-17")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken, setToken } from "@/utils/token";
import { mapState } from "vuex";
import QRCode from "@/components/QRCode.vue";
import { v4 as uuidv4 } from "uuid";
import i18n from "@/lang";
export default {
  components: { QRCode },
  data() {
    return {
      login: {
        account: "",
        pwd: "",
        code: "",
      },
      menuInd: 0,
      targetMenuInd: false,
      menuLeftVal: 0,
      helps: [
        {
          name: "会员俱乐部",
          linka: "/vipIntroduce",
          layout: "vipIntroduce",
          icon: "icon-vip",
        },
        {
          name: "优惠活动",
          linka: "/activity/discount",
          layout: "agent",
          icon: "icon-activity",
        },
        // {
        //   name: "赞助",
        //   linka: "/sponsor/madrid",
        //   layout: "madrid",
        //   img: require("@/assets/images/nav03.png"),
        // },
        // {
        //   name: "百万中奖榜",
        //   linka: "/agent",
        //   layout: "discount",
        //   icon: "icon-rank",
        // },
        {
          name: "下载中心",
          linka: "/download",
          layout: "download",
          icon: "icon-download",
        },
      ],
      isShowSelect: false,
      navIndex: 0,
      navTotalWid: 0,
      navWid: 0,
      isNavShow: false,
      moveNavWid: 0,
      moveYnumber: 0,
      selectType: "",
      loginMenu: [
        {
          name: this.$t("components.Header.877183-18"),
          icon: "icon-user",
          link: "/mine/userInfo",
        },
        {
          name: this.$t("components.Header.877183-23"),
          icon: "icon-message",
          link: "/mail",
        },
      ],
      codeUUid: "",
      codeImg: "",
      jumpContact: false,
      isCode: false,
      menuIndex: 1,
      isLoadOver: false,
      menus: null,
      langList: null, //语言列表
      langVal: "", // 语言值
      areaList: null,
      areaVal: "",
      moneyTimer: null,
    };
  },
  watch: {
    $route(to) {
      if (!this.menus) return;
      this.menus.forEach((item) => {
        item.isSelect = false;
        if (to.meta.data === item.layout) {
          item.isSelect = true;
          this.selectType = to.meta.data;
        }
      });
    },
    userInfo(val) {
      this.getArea(val);
    },
  },
  computed: {
    ...mapState([
      "isLogin",
      "userInfo",
      "accountMoney",
      "baseInfo",
      "languages",
      "registerInfo",
    ]),
  },
  mounted() {
    this.getApiGameCategoryList();
    this.getCaptcha();
    this.initMoney();
    // this.getAllPlatTypes();
    this.getLang();
    this.getArea();
  },
  methods: {
    tabLange(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.656206-17"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "lang");
    },
    tabArea(e) {
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }
      this.changeLanguageAndCurrenc(e, "area");
    },
    changeLanguageAndCurrenc(val, type) {
      let params = {};
      if (type === "lang") {
        params.languageType = val;
      }
      if (type === "area") {
        params.currencyType = val;
      }
      this.$Api.changeLanguageAndCurrenc(params).then((res) => {
        if (res) {
          if (type === "lang") {
            let langtarget = this.languages.find(
              (item) => item.id === val
            ).type;
            this.$i18n.locale = langtarget;
            localStorage.setItem("lang", langtarget);
          }
          location.reload();
        }
      });
    },
    // 语言数据
    getLang() {
      this.$Api
        .getSysDictionary({ dictionaryValue: "language_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("lang");
            let localVal = "";
            if (getlang) {
              localVal = this.languages.find(
                (item) => item.type === getlang
              ).id;
            }
            this.langList = res.sysDictionaryList;
            // 语言初始值
            const lang = res.sysDictionaryList.find(
              (item) => item.value === localVal
            );
            if (lang) {
              this.langVal = localVal
                ? lang.name
                : res.sysDictionaryList[0].name;
            }
          }
        });
    },
    // 货币数据
    getArea(val) {
      this.$Api
        .getSysDictionary({ dictionaryValue: "currency_type" })
        .then((res) => {
          if (res) {
            // 本地是否有设置语言
            const getlang = localStorage.getItem("area");
            this.areaList = res.sysDictionaryList;
            let localVal = "";
            if (val) {
              localVal = val.currencyType;
            }
            // 语言初始值
            this.areaVal = localVal
              ? res.sysDictionaryList.find((item) => item.value === localVal)
                  .name
              : res.sysDictionaryList[0].name;
          }
        });
    },
    getApiGameCategoryList() {
      // 设定导航栏字段
      console.log(111);
      const layouts = [
        {
          aid: "1",
          type: "realbet",
          link: "/game/realbet",
          name: this.$i18n.t("navList.sx"),
        },
        {
          aid: "2",
          type: "egame",
          link: "/game/egame?code=ag",
          name: this.$i18n.t("navList.dz"),
        },
        {
          aid: "3",
          type: "lottery",
          link: "/game/lottery",
          name: this.$i18n.t("navList.cp"),
        },
        {
          aid: "4",
          type: "sport",
          link: "/game/sport",
          name: this.$i18n.t("navList.ty"),
        },
        {
          aid: "5",
          type: "gaming",
          link: "/game/gaming",
          name: this.$i18n.t("navList.dj"),
        },
        {
          aid: "6",
          type: "fishing",
          link: "/game/fishing?code=bg",
          name: this.$i18n.t("navList.yl"),
        },
        {
          aid: "7",
          type: "chess",
          link: "/game/chess",
          name: this.$i18n.t("navList.qp"),
        },
      ];
      this.$Api.getApiGameCategoryList().then((res) => {
        if (res) {
          res.apiGameCategoryList.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.layout = row.type;
                item.link = row.link;
                // item.gameTypeName = row.name
              }
            });
          });
          this.menus = res.apiGameCategoryList.filter(
            (item) => item.gameType !== "0"
          );
          this.menus.unshift({
            gameTypeName: i18n.t("components.Header.877183-24"),
            link: "/",
            list: [],
            layout: "home",
          });
          this.menus.forEach((item) => {
            layouts.find((row) => {
              if (item.gameType === row.aid) {
                item.gameTypeName = row.name;
              }
            });
          });
          this.getAllPlatTypes();
        }
      });
    },
    // 打开游戏
    openGame(val) {
      if (val.gameType === 2) {
        this.$router.push({
          path: "/game/egame",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (val.gameType === 6) {
        this.$router.push({
          path: "/game/fishing",
          query: {
            code: val.platType,
          },
        });
        this.isShowSelect = false;
        return;
      }
      if (!this.isLogin) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
        return;
      }

      const params = {
        platType: val.platType,
        gameType: val.gameType,
        gameCode: val.gameCode,
        devices: 0,
      };
      this.$Api.getGameUrl(params).then((res) => {
        if (res) {
          const url = JSON.parse(res.data);
          window.open(url.url, "_blank");
        }
      });
    },
    // 导航
    async getAllPlatTypes() {
      this.$store.dispatch("setNavsValAc", false);
      if (this.menuIndex === 8 && this.menus.length && this.isLoadOver) {
        this.$store.dispatch("setNavsValAc", true);
        return;
      }
      this.isLoadOver = false;
      const params = {
        gameType: this.menuIndex,
        device: 1,
      };
      await this.$Api.getAllPlatTypes(params).then((res) => {
        let obj = [];
        if (res) {
          Object.keys(res.platTypes).map((item) => {
            obj.push(res.platTypes[item]);
          });
          obj.forEach((item) => {
            Object.assign(item, {
              icon: require("@/assets/images/gameicon/" +
                item.platType +
                ".png"),
            });
          });
          // 改造后的导航
          this.menus.forEach((item) => {
            if (Number(item.gameType) === this.menuIndex) {
              this.$set(item, "list", obj);
            }
          });
          this.menuIndex++;
          this.getAllPlatTypes();
          if (this.menuIndex === 7) {
            this.$store.dispatch("setNavsListAc", this.menus);
            this.isLoadOver = true;
          }
        }
      });
    },
    inputBlur() {
      setTimeout(() => {
        this.isCode = false;
      }, 100);
    },
    // 查看code
    inputCode() {
      this.isCode = true;
    },
    initMoney() {
      this.getUserBalance();
      // this.moneyTimer = setInterval(() => {
      //   this.getUserBalance();
      // }, 5000);
    },
    jumpContactWeb() {
      this.jumpContact = false;
      // const url = this.$router.resolve("/customer/main");
      window.open(this.baseInfo[6].configValue, "_blank");
    },
    async getCaptcha() {
      this.codeUUid = uuidv4();
      let res = await this.$Api.getCaptcha(this.codeUUid);
      let imgUrl =
        "data:image/png;base64," +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
      this.codeImg = imgUrl;
    },
    loginSend() {
      const { account, pwd, code } = this.login;
      const isCode = this.registerInfo[12].isShow ? code : true;
      if (!(account && pwd && isCode)) return;
      const params = {
        userName: account,
        password: pwd,
        code: code,
        codeId: this.codeUUid,
      };
      this.$Api.loginSend(params).then((res) => {
        if (res) {
          setToken(res.token);
          this.$store.dispatch("setHasLoginAc", true);
          this.$message({
            message: this.$t("components.Header.877183-28"),
            type: "success",
          });
          this.login = {
            account: "",
            pwd: "",
            code: "",
          };
          this.getUserBalance();
          this.$Api.getUserInfo().then((res) => {
            if (res) {
              this.$store.dispatch("setUserInfoAc", res.userInfo);
              if (res.userInfo.languageType) {
                let langtarget = this.languages.find(
                  (item) => item.id === res.userInfo.languageType
                ).type;
                this.$i18n.locale = langtarget;
                localStorage.setItem("lang", langtarget);
                location.reload();
              }
            }
          });
          this.$router.push("/");
          return;
        }
        this.getCaptcha();
      });
    },
    tabSelectHelp(val, index) {
      if (val.layout) {
        this.isShowSelect = true;
      } else {
        this.isShowSelect = false;
      }
      this.selectType = val.layout;
    },
    tabSelect(val, index) {
      this.selectType = val.layout;
      this.menuInd = index;
      this.menuLeftVal = 0;
      this.navIndex = 0;
      if (this.menus[index].list && this.menus[index].list.length) {
        this.isShowSelect = true;
        const rows = this.$refs.targetGame;
        if (rows && rows.length) {
          const number = rows.length;
          // 单个宽度
          this.navWid = rows[0].clientWidth + 20;
          // 取总长
          this.navTotalWid = this.navWid * number;
          // 取余数
          this.moveYnumber = (this.navTotalWid - 1200) % this.navWid;
          // 可移动的长度
          const length = Math.ceil((this.navTotalWid - 1200) / this.navWid);
          if (length > 0) {
            this.moveNavWid = length;
          } else {
            this.moveNavWid = 0;
          }
          // 判断是否显示左右切换
          const isShowNavTab = (rows[0].clientWidth + 20) * number > 1200;
          if (isShowNavTab) {
            this.isNavShow = true;
          }
        }
      } else {
        this.isShowSelect = false;
      }
    },
    arrowNavTab(val) {
      // 上下切换
      if (val === "prev") {
        if (this.navIndex === 0) return;
        this.navIndex--;
        this.menuLeftVal = -this.navWid * this.navIndex;
      } else {
        this.navIndex++;
        if (this.moveNavWid < this.navIndex) return;
        this.menuLeftVal = -this.navWid * this.navIndex;
        // console.log(this.navIndex, 8888, this.moveNavWid);
      }
    },
    moveOut() {
      this.isShowSelect = false;
    },
    jumpWeb(val) {
      // console.log(this.$route, val.layout);
      // if (this.$route.meta.data === val.layout) return;
      this.$router.push(val.link);
      this.isShowSelect = false;
    },
    jumpHelp(val) {
      if (
        !this.isLogin &&
        ["/mine/details", "/activity/bettingGift"].includes(val)
      ) {
        this.$confirm(
          this.$t("components.Header.877183-25"),
          this.$t("components.Header.877183-26"),
          {
            confirmButtonText: this.$t("components.Header.877183-27"),
            showCancelButton: false,
            customClass: "el-pop-wid",
          }
        )
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            // console.log(11);
          });
        return;
      }
      this.$router.push(val.linka);
      this.isShowSelect = false;
    },
    loginOut() {
      this.$message({
        message: this.$t("components.Header.877183-29"),
        type: "success",
      });
      removeToken();
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  padding: 16px 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.4s;
  background: #000;

  &-wid {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 1200px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .logo {
    img {
      width: 112px;
      height: 44px;
    }
  }

  .nav,
  .help {
    flex: 1;

    ul {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 20px;

      li {
        font-size: 16px;
        position: relative;
        color: white;

        &:hover {
          color: var(--theme);

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav {
    ul {
      gap: 20px;
      li {
        display: flex;
        align-items: center;
        gap: 4px;
        .arrow {
          font-size: 10px;
        }
        &.hover {
          color: var(--theme);
        }
      }
    }
  }

  .help {
    flex: none;

    ul {
      li {
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
      }
    }

    .img {
      font-size: 24px;
    }

    .text {
      position: relative;
    }
  }

  .login {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 10px;
    .btn-log,
    .btn-reg {
      padding: 0 20px;
      height: 36px;
      border-radius: 20px;
      font-size: 15px;
      background: linear-gradient(180deg, #ffffff 10%, #ffffffc7, #dfdfdf 80%);
      box-shadow: 0 2px 0px inset #dcdcdc;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid white;
    }

    .btn-log {
      background: linear-gradient(180deg, #eecaa3 10%, #dda464, #ba864b 80%);
      box-shadow: 0 2px 0px inset #d8a85a;
      border: 1px solid #f3cf7d;
    }
  }
}

.nav-bg {
  background: #282828;
  background-size: 100% 100%;
  position: absolute;
  top: 76px;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: all 0.4s;
}

.nav-list {
  position: relative;
  user-select: none;
  padding: 30px 0 30px;

  .arrow {
    position: absolute;
    top: 50%;
    left: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebc35f2b;
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;
    transform: translateY(-50%);

    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-right: 2px solid var(--theme);
      border-bottom: 2px solid var(--theme);
      top: 50%;
      left: 50%;
      transform: translate(-60%, -50%) rotate(-45deg);
    }

    &.next {
      left: auto;
      right: 0;
      margin-right: -40px;
    }

    &.prev {
      margin-left: -40px;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &-box {
    overflow: hidden;
    position: relative;
  }

  &-scroll {
    width: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.4s;
  }
}

.nav-row {
  display: flex;
  align-items: center;
  width: 260px;
  flex: none;
  gap: 20px;

  &:hover {
    .img {
      transform: scale(1.1);
    }
  }

  .img {
    transition: all 0.4s;
    position: relative;
    width: 100%;
  }

  .left {
    width: 210px;
    cursor: pointer;
    font-size: 16px;
    p {
      text-align: center;
      color: #aaaaaa;
    }
  }
}

.nav-game-icon {
  display: flex;
  align-items: center;

  .icon {
    width: 48px;
    height: 48px;
    flex: none;
    border-radius: 8px;
    background: linear-gradient(180deg, white, #ebedfb);
    border-bottom: 2px solid white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
      filter: invert(30%);
    }
  }

  .text {
    margin-left: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
    }

    p {
      font-size: 12px;
      white-space: nowrap;
    }
  }
}

.nav-ty-img {
  width: 130px;
  margin-left: 40px;
  margin-right: -40px;

  img {
    width: 100%;
  }
}

.fadeIn {
  transform: translateY(0);
}

.help-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 100px;

  .left {
    display: flex;
    align-items: center;

    img {
      width: 240px;
      cursor: pointer;

      &.link {
        transition: all 0.3s;

        &:hover {
          transform: scale(1.04);
        }
      }
    }

    .title {
      margin-left: 50px;

      span {
        font-size: 40px;
        font-weight: bold;
      }

      p {
        font-size: 24px;
        margin: 10px 0 20px;
      }

      img {
        width: 400px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      span {
        font-size: 18px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
      }

      img {
        width: 110px;
      }

      p {
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }
}

.link-img {
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.04);
  }
}

.help-nav-discount {
  display: flex;
  align-items: center;

  li {
    display: flex;
    flex-direction: column;

    .img1 {
      width: 300px;
    }

    .link-img {
      width: 290px;
    }
  }
}

.help-nav-download {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .row {
    display: flex;
    align-items: center;
    margin: 0 30px;

    .left {
      width: 240px;
      margin-right: 16px;
    }

    .right {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .code {
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 8px;
        font-size: 12px;
      }

      .small {
        white-space: nowrap;
        display: block;
      }

      a {
        color: #2599f8;
      }
    }
  }
}

.login-nav {
  display: flex;
  align-items: center;

  .login-nav-ul {
    display: flex;
    align-items: center;

    li {
      margin-left: 12px;
      cursor: pointer;

      span {
        width: 32px;
        height: 32px;
        display: block;

        img {
          width: 100%;
          height: 100%;

          &.xz {
            display: none;
          }
        }
      }

      p {
        font-size: 16px;
      }

      &:hover {
        p {
          color: #2599f8;
        }

        .xz {
          display: block !important;
        }

        .mr {
          display: none !important;
        }
      }
    }
  }

  .login-info {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      em {
        transform: rotate(-180deg);
      }

      .login-menus {
        display: block;
        transform: scale(1);
        opacity: 1;
      }
    }

    .name {
      font-family: PingFang SC;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: white;

      .level-img {
        height: 12px;
        width: 26px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        margin-left: 4px;
      }
    }

    .photo {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      overflow: hidden;
    }

    p {
      font-style: normal;
      font-size: 14px;
      color: var(--theme);
      display: flex;
      align-items: center;

      em {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        font-size: 10px;
        margin-left: 4px;
        transition: all 0.4s;
      }
    }
  }
}

.login-menus {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  width: 180px;
  background: #151515;
  border-radius: 10px;
  padding: 20px 10px;
  transition: all 0.4s;
  transform: scale(0.5);
  opacity: 0;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);

  &::before {
    position: absolute;
    content: "";
    top: -6px;
    border: 6px solid transparent;
    border-bottom-color: #151515;
    border-top: 0;
    right: 20px;
  }

  &::after {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 22px;
    content: "";
  }
  dl {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    padding-bottom: 16px;
    dd {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      .icon {
        font-size: 20px;
        color: var(--theme);
      }
      .text {
        color: white;
        font-size: 12px;
        opacity: 0.5;
      }
    }
  }

  ul {
    padding: 4px 16px 0;
    li {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      color: white;
      .icon {
        font-size: 17px;
        color: var(--theme);
      }
      .flex {
        flex: 1;
        font-size: 12px;
      }
      .icon-arrow-r {
        font-size: 10px;
        opacity: 0.5;
      }
    }
  }

  .login-out {
    height: 32px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 1px solid #ea5248;
    font-size: 14px;
    background-color: #ea5248;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    text-align: center;
    margin: 8px 8px 0;

    &:hover {
      border: 1px solid #ea5248;
      color: white;
    }
  }
}

.contact-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-main {
  width: 480px;
  border-radius: 10px;
  overflow: hidden;
  background: white;

  img {
    width: 100%;
  }

  .main {
    padding: 24px;

    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .link {
    border-radius: 20px;
    margin: 20px auto 0;
    display: block;
    min-width: 240px;
  }
}

/deep/.lang-style {
  .el-input__inner {
    border-radius: 20px !important;
    height: 28px;
    line-height: 28px;
    width: 90px;
    font-size: 14px;
  }

  .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
