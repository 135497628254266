var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-wid"},[_c('div',{staticClass:"logo",on:{"click":function($event){return _vm.$router.push('/')}}},[(_vm.baseInfo && _vm.baseInfo.length)?_c('img',{attrs:{"src":_vm.baseText('logo')}}):_vm._e()]),(_vm.menus)?_c('div',{staticClass:"nav"},[_c('ul',_vm._l((_vm.menus),function(item,index){return _c('li',{key:index,class:{ hover: _vm.$route.meta.data === item.layout },on:{"mousemove":function($event){return _vm.tabSelect(item, index)},"click":function($event){return _vm.jumpWeb(item)}}},[_vm._v(" "+_vm._s(item.gameTypeName)+" "),(item.list && item.list.length)?_c('span',{staticClass:"arrow icon-arrow-d"}):_vm._e()])}),0)]):_vm._e(),_c('div',{staticClass:"help"},[_c('ul',_vm._l((_vm.helps),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.jumpHelp(item)},"mouseenter":function($event){return _vm.tabSelectHelp(item, index)}}},[_c('span',{staticClass:"img",class:item.icon}),_c('p',{staticClass:"text"},[_vm._v(_vm._s(item.name))])])}),0)]),(!_vm.isLogin)?_c('div',{staticClass:"login"},[_c('div',{staticClass:"btn-log",on:{"click":_vm.openLoginPop}},[_vm._v(_vm._s(_vm.$t("logins.dl")))]),_c('div',{staticClass:"btn-reg",on:{"click":_vm.openRegisterPop}},[_vm._v(" "+_vm._s(_vm.$t("logins.zc"))+" ")])]):_vm._e(),(_vm.isLogin)?_c('div',{staticClass:"login-nav"},[_c('div',{staticClass:"login-info"},[(_vm.userInfo)?_c('img',{staticClass:"photo",attrs:{"src":_vm.userInfo.photoUrl}}):_vm._e(),(_vm.userInfo)?_c('div',{staticClass:"info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo.userName)),(_vm.userInfo.levelId)?_c('span',{staticClass:"level-img",style:({
                backgroundImage:
                  'url(' +
                  require('@/assets/pic/index-level-' +
                    _vm.userInfo.levelId +
                    '.png') +
                  ')',
              })}):_vm._e()]),(_vm.accountMoney)?_c('p',{staticClass:"text-ak"},[_vm._v(" ¥"+_vm._s(_vm.accountMoney.userBalance ? _vm.accountMoney.userBalance.toFixed(2) : "0.00")+" "),_c('em',{staticClass:"icon-arrow-d"})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"login-menus"},[_c('dl',[_c('dd',{on:{"click":function($event){return _vm.checkAuthUrl('/finance/deposit')}}},[_c('span',{staticClass:"icon icon-purse"}),_c('p',{staticClass:"text"},[_vm._v("充值")])]),_c('dd',{on:{"click":function($event){return _vm.checkAuthUrl('/finance/withdrawal')}}},[_c('span',{staticClass:"icon icon-withdraw"}),_c('p',{staticClass:"text"},[_vm._v("提现")])]),_c('dd',{on:{"click":function($event){return _vm.$router.push('/finance/transfer')}}},[_c('span',{staticClass:"icon icon-transfer"}),_c('p',{staticClass:"text"},[_vm._v("转账")])])]),_c('ul',_vm._l((_vm.loginMenu),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.$router.push(item.link)}}},[_c('span',{staticClass:"icon",class:item.icon}),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"number"})]),_c('span',{staticClass:"icon-arrow-r"})])}),0),_c('div',{staticClass:"login-out",on:{"click":_vm.loginOut}},[_vm._v(" "+_vm._s(_vm.$t("components.Header.877183-3"))+" ")])])])]):_vm._e()]),(_vm.isShowSelect)?_c('div',{staticClass:"nav-bg fadeIn",on:{"mouseleave":_vm.moveOut}},[_c('div',{staticClass:"yx-wid-12"},[(
          [
            'sport',
            'realbet',
            'chess',
            'gaming',
            'lottery',
            'egame',
            'fishing',
          ].includes(_vm.selectType)
        )?_c('div',{staticClass:"nav-list"},[(_vm.navIndex > 0)?_c('div',{staticClass:"arrow prev",on:{"click":function($event){return _vm.arrowNavTab('prev')}}}):_vm._e(),(_vm.navIndex !== _vm.moveNavWid && _vm.isNavShow)?_c('div',{staticClass:"arrow next",on:{"click":function($event){return _vm.arrowNavTab('next')}}}):_vm._e(),_c('div',{ref:"targetTotal",staticClass:"yx-wid-12 nav-list-box"},[_c('div',{staticClass:"nav-list-scroll",style:({ transform: 'translateX(' + _vm.menuLeftVal + 'px)' })},[_vm._l((_vm.menus[_vm.menuInd].list),function(item,index){return _c('div',{key:index,ref:"targetGame",refInFor:true,staticClass:"nav-row"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.openGame(item)}}},[(item.pcNavbarImgUrl)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pcNavbarImgUrl),expression:"item.pcNavbarImgUrl"}],staticClass:"img"}):_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/menus/01.png")}}),_c('p',[_vm._v(_vm._s(item.apiTitle))])])])}),(_vm.menuInd === 1)?_c('div',{staticClass:"nav-ty-img"},[_c('img',{attrs:{"src":require("@/assets/images/menus/04.png")}})]):_vm._e()],2)])]):_vm._e()])]):_vm._e(),(_vm.jumpContact)?_c('div',{staticClass:"contact-box"},[_c('div',{staticClass:"contact-main"},[_c('img',{attrs:{"src":require("@/assets/images/contact01.png")}}),_c('div',{staticClass:"main"},[_c('h2',[_vm._v(_vm._s(_vm.$t("components.Header.877183-12")))]),_c('p',{staticClass:"text-red"},[_vm._v(" "+_vm._s(_vm.$t("components.Header.877183-13"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("components.Header.877183-14"))),_c('span',{staticClass:"text-red"},[_vm._v(_vm._s(_vm.$t("components.Header.877183-15")))]),_vm._v(_vm._s(_vm.$t("components.Header.877183-16"))+" ")]),_c('el-button',{staticClass:"link",attrs:{"type":"primary"},on:{"click":_vm.jumpContactWeb}},[_vm._v(_vm._s(_vm.$t("components.Header.877183-17")))])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }